import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { cifUs, cifPl } from '@coreui/icons';
import avatar from '../../assets/images/avatar.jpg';
import { CustomerDetails, PermissionGroup, Country } from '../../types';

export interface UsersSliceState {
    users: any;
    customers: CustomerDetails[];
    permissionGroups: PermissionGroup[];
    permissions: any;
    countries: Country[];
}
const initialState = {
  countries: [],
  customers: [],
  permissionGroups: [],
  permissions: [],
  users: [
    {
      avatar: { src: avatar },
      user: {
        first_name: 'Yiorgos Avraamu',
        last_name: 'Avraamu',
        new: true,
        registered: 'Jan 1, 2021',
        email: 'test@gmail.com',
      },
      country: { name: 'USA', flag: cifUs },
      role: [],
      status: 'Active',
      activity: '10 sec ago',
    },
   
  ],
} as UsersSliceState;

const usersSlice = createSlice({
    name: 'usersSlice',
    initialState,
    reducers: {
      setUsers(state, action: PayloadAction<any>) {
        let staff_users = [];
        for (let user of action.payload) {
          if(user.id !== 1) {
            const userObject = {
              avatar: { src: avatar },
              user: {
                id: user.id,
                first_name: user.first_name,
                last_name: user.last_name,
                new: true,
                registered: 'Jan 1, 2021',
                email: user.email,
              },
              country: { name: 'Poland', flag: cifPl },
              role: user.groups,
              is_active: user.is_active,
              activity: 'Last week',
            }
            staff_users.push(userObject);
          }
        }
        state.users = staff_users;
      },
      setCountries(state, action: PayloadAction<Country[]>) {
        state.countries = action.payload;
      },
      setCustomers(state, action: PayloadAction<any>) {
        state.customers = action.payload;
      },
      setPermissionGroups(state, action: PayloadAction<any>) {
        state.permissionGroups = action.payload;
      },
      setPermissions(state, action: PayloadAction<any>) {
        state.permissions = action.payload;
      },
     
    },
  })
  
  export const { setCountries, setUsers, setCustomers, setPermissionGroups, setPermissions} = usersSlice.actions;
  export default usersSlice.reducer;