import { cilReload } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CHeader, CNav, CNavItem, CRow, CCol, CSpinner, CButton } from "@coreui/react";
import { useEffect, useState } from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import { formattedPrice } from "../../constants";
import { Package } from "../../types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

interface PackageDetailStickyHeaderProps {
    numOfTrucks: number;
    loading: boolean;
    truckCapacity: number;
    totalPrice: number;
    productCart: any;
    packageItem: Package | undefined;
    disableConfirmButton: boolean;
    calculateTruckCapacity: () => void;
    handleShowModal: () => void;
    
} 

const PackageDetailStickyHeader = (props: PackageDetailStickyHeaderProps) => {
    const {numOfTrucks, loading, truckCapacity, productCart, packageItem,
         disableConfirmButton, totalPrice, calculateTruckCapacity, handleShowModal} = props;
    const [showHeader, setShowHeader] = useState<boolean>(false);
    const {t} = useTranslation();
    
    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY || document.documentElement.scrollTop;
            // Define the threshold at which the header should appear (e.g., 100px)
            const threshold = 290;
            if (scrollPosition > threshold) {
                setShowHeader(true);
            } else {
                setShowHeader(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []); // Run this effect only once on component mount

    return (
        <CHeader position="sticky" className={`mb-4 ${showHeader ? 'd-block' : 'd-none'}`}>
                <CNav style={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                    <CNavItem>
                        {
                            loading ?
                                <CRow>
                                    <CCol>
                                        <CSpinner />
                                    </CCol>
                                </CRow>
                                : null
                        }
                        <CRow>
                            <CCol>
                                <h6 className="white">{numOfTrucks} x {t('adminDashboard.Packages.truck_capacity_text')} ({truckCapacity} m)</h6>
                                <ProgressBar completed={`${truckCapacity}`} maxCompleted={13.5} bgColor="#333333" width="100%" customLabel=" " />
                            </CCol>
                        </CRow>
                    </CNavItem>
                    <CNavItem>
                        <CButton
                            className='elv-btn-header'
                            variant="outline"
                            shape="square"
                            style={{ marginLeft: 20 }}
                            onClick={calculateTruckCapacity}
                            disabled={productCart.length == 0}
                        >
                            <CIcon
                                icon={cilReload}
                            />
                        </CButton>
                    </CNavItem>
                    <CNavItem>
                        <CRow>
                            <CCol>
                                {
                                    packageItem && packageItem.status === 'A' ?
                                        <div style={{ marginLeft: '20px', marginRight: '12px' }}>
                                            <CButton
                                                className='elv-btn-header'
                                                variant="outline"
                                                disabled={productCart?.length === 0 || disableConfirmButton || loading}
                                                onClick={() => {handleShowModal()}}
                                            >
                                                {t('adminDashboard.Packages.confirm_package')}
                                            </CButton>
                                        </div>
                                        : null
                                }
                            </CCol>
                        </CRow>
                    </CNavItem>
                    <CNavItem>
                        <CRow>
                            <CCol>
                                <h5 className="margin-left-12 margin-right-12 white"><b>{t('adminDashboard.Packages.total_items')}: {productCart.length}</b></h5>
                            </CCol>
                        </CRow>
                    </CNavItem>
                    <CNavItem>
                        <CRow>
                            <CCol>
                                <h5 className="margin-left-12 margin-right-12 white" ><b>{t('adminDashboard.Packages.grand_total_euro')}: {formattedPrice(totalPrice)}</b></h5>
                            </CCol>
                        </CRow>
                    </CNavItem>
                </CNav>
            </CHeader>
    )
};
export default PackageDetailStickyHeader;