import {Suspense, useEffect, useState} from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import MainHeader from '../../components/header-components/MainHeader';
import AdminSidebar from '../../components/sidebar/SideBar';
import { CCol, CContainer, CRow, CSpinner } from '@coreui/react';
import { useDispatch } from 'react-redux';
import '../../../../src/scss/style.scss';
import '../../AdminStyles.css';
import { getRefreshToken, getAuthUser } from "../../../services/AuthService";
import {setLoggedinUser} from '../../../redux/slices/AuthSlice';
import { Utils } from '../../components/utils';
import { useTranslation } from 'react-i18next';
import { WebsocketsUtils } from '../../components/utils/WebsocketsUtils';

function AdminHomeScreen() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { t, i18n } = useTranslation();
 
  const {
    getOrdersData, getPackagesData, getCustomersData, logout, getAllProductSupplyTypes,
    getAllProductSupplies, getAllProductSuppliers, getPendingPackagesData,
    getAvailablePalletsCount, getAvailableProductsCount, getAllPackagesCount, getAllOrdersCount
  } = Utils();
  // const {startListeners} = WebsocketsUtils();
  
  const [isLoading, setIsLoading] = useState<boolean>(true);
  
 
  const getMainDashboardData = () => {
    // startListeners();
    getAllPackagesCount(); getAllOrdersCount();
    getAvailablePalletsCount(); getAvailableProductsCount(); getOrdersData();
    getAllProductSupplies(); getPackagesData();
    getPendingPackagesData(); getCustomersData(); 
    getAllProductSuppliers(); getAllProductSupplyTypes();
    // getAllProductSubCategories();
    // getAllProductArticles();
    // getAllProductCategoryQuestions();
    // getAllPalletsData();
    //  getAllProductsData(1);
    //  getAllProductBrands();
    //  getAllProductCategories();
    // getUsageData();
  }
 
  useEffect(()=> {
    setInterval(() => {
      getRefreshToken().then((response) => {
      }).catch((e) => {
        // logout the user here.
        logout();
      });
    }, 100000); // after every 5 minutes auth token gets refreshed so that user does not faces any un authorized errors.
   
    getAuthUser().then((response) => {
      dispatch(setLoggedinUser(response.data));
      i18n.changeLanguage(response?.data?.preferred_language);
      setIsLoading(false);
    }).catch((e) => {
      logout();
    });

    if(location.pathname !== '/admin/dashboard/product/details' && location.pathname !== '/admin/dashboard/pallet/details') {
      getMainDashboardData();
    }
    
    // Sales Analytics API Route.
    // getAllAnalyticsData();
  },[]);

  return (
    <div>
      <AdminSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <MainHeader />
        <div className="body flex-grow-1 px-3 zero-margin" style={{width: '100%'}}>
          <CContainer className='max-width' lg>
            <Suspense fallback={<CSpinner color="primary" />}>
              {
                isLoading ?
                <CRow>
                  <CCol className='text-center'>
                    <CSpinner />
                  </CCol>
                </CRow>
                :  <Outlet />
              }             
            </Suspense>
          </CContainer>
        </div>
      </div>
    </div>
  );
}

export default AdminHomeScreen;
