

import { useEffect, useState } from 'react'
import 'simplebar/dist/simplebar.min.css';
import { CModal, CModalBody, CModalHeader, CModalFooter, CButton, CCardBody } from '@coreui/react-pro';
import '../CustomerStyles.css';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useNavigate } from 'react-router-dom';
import { CustomerPatchData, Package } from '../../types';
import { CBadge, CCard, CCardText, CCardTitle, CCol, CHeader, CRow } from '@coreui/react';
import { getPackages } from '../../services/PackageService';
import { setPackages } from '../../redux/slices/PackageSlice';
// import { t } from "i18next";
import { useTranslation } from 'react-i18next';
import { Utils } from '../../admin/components/utils';
import { updateCustomerData, getCustomerData } from '../../services/CustomerService';
import { setSelectedTab } from '../../redux/slices/CustomerTotalOrdersSlice';
import { setSelectionPercent } from '../../redux/slices/AuthSlice';

const CustomerPackages = (props: any) => {
  const packges: Package[] = useSelector((state: RootState) => state.packageSlice.packages);
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const {t} = useTranslation();
  const [showTermsConditionsModal, setTermsConditionsModal] = useState<boolean>(false);
  const [showLoggedOutModal, setLoggedOutModal] = useState<boolean>(false);
  const [showAccepTermsModal, setAccepTermsModal] = useState<boolean>(false);
  const [checkTermsAcceptanceDate, setCheckTermsAcceptedDate] = useState(null);
  const [loading, setLoading] = useState(true);
  const { logout } = Utils();

  const getAllPackages = () => {
    getPackages().then((response) => {
      let updatedPackagesArray: Package[] = [];
      for (let responseItem of response?.data?.results) {
        let new_date = new Date(responseItem.created_at);
        updatedPackagesArray.push({
          ...responseItem,
          customer_name: responseItem?.customer_details?.user?.first_name + " " + responseItem?.customer_details?.user?.last_name,
          creator_name: responseItem?.creator?.first_name + " " + responseItem?.creator?.last_name,
          created_at: new_date,
        });
      }
      dispatch(setPackages(updatedPackagesArray));
    }).catch((e) => {
      dispatch(setPackages([]));
      console.log('get packages error: ', e);
    });
  };

  // Get customer data
  const getCurrentCustomerData = () => {
    getCustomerData().then((response) => {
      setCheckTermsAcceptedDate(response?.data?.terms_accepted_date);
      dispatch(setSelectionPercent(response?.data?.selection_percent));
    }).catch((e) => {
      console.log('get packages error: ', e);
    }).finally(() => {
      setLoading(false); 
    });
  };

  // Update customer data partially
  const updateCustomer = () => {
    const patchData: CustomerPatchData = {
      terms_accepted_date: new Date().toISOString()
    };
    updateCustomerData(patchData).then((response) => {
    }).catch((e) => {
      console.log('Error updating customer data: ', e);
    });
  };

  useEffect(() => {
    getAllPackages();
    getCurrentCustomerData();
    dispatch(setSelectedTab(1));
  }, []);


  useEffect(() => {
    if (!loading && checkTermsAcceptanceDate === null) {
      setTermsConditionsModal(true);
    }
  }, [loading, checkTermsAcceptanceDate]);

  // Function will be invoked in case of acceptance of terms & condition
  const handleAcceptTerms = () => {
    setTermsConditionsModal(false);
    setAccepTermsModal(true);
    updateCustomer();
    setTimeout(() => {
      setAccepTermsModal(false);
    }, 3500);
  }

  // Function will be invoked in case of terms & condition rejection
  const handleRejectTerms = () => {
    setTermsConditionsModal(false);
    setLoggedOutModal(true);
    setTimeout(() => {
      logout();
    }, 3500);

  }

  return (
    <div>
      <CHeader><h1><b>{t('adminDashboard.Customers.my_packages')}</b></h1></CHeader>
      <CRow>
        {
          packges && packges.length > 0 ?
            packges?.map((packageItem: Package) => {
              if (packageItem.status !== 'F') {
                // Currently Customer can also view the modified package but cannot edit it further
                return (
                  <CCol xs={12} md={3} style={{ marginTop: 24, marginBottom: 24 }}>
                    <CCard // Parent card component.
                      className='unselected-card'
                      onClick={() => {
                        navigation('/customer/packages/details/', { state: { packageItem: packageItem } })
                      }}
                    >
                      <CCardBody>
                        <CCardTitle> {t('adminDashboard.Packages.package')} {packageItem.id}</CCardTitle>
                        <CCardText>
                          <div><b> {t('adminDashboard.OrdersWords.created_by')} {packageItem.creator_first_name} {packageItem.creator_last_name}</b></div>
                          <div><b> {t('adminDashboard.Packages.validity_hours')} {packageItem.package_validity_hours}</b></div>
                          <div><b> {t('adminDashboard.Products.number_of_products')} {packageItem.quantity}</b></div>
                          <div>{packageItem.status == 'M' ? <CBadge color='success' >{t('adminDashboard.CommonWords.order_placed')}</CBadge> : <CBadge color='warning' >{t('adminDashboard.CommonWords.pending_approval')}</CBadge>} </div>
                        </CCardText>
                      </CCardBody>
                    </CCard>
                  </CCol>
                )
              }
            })
            :
            <h5 className='text-center'>No package assigned at the moment.</h5>
        }
      </CRow>
      {/* Modal */}
      <CModal visible={showTermsConditionsModal} className="centered-modal" style={{ width: '50vw', height: '90vh', maxHeight: '90vh' }}>
        <CModalHeader closeButton onClick={handleRejectTerms}>
          <div>
            <b>Elvinci's Terms and Conditions</b>
          </div>
        </CModalHeader>
        <CModalBody>
          <iframe src={require('../../assets/pdf/terms_conditions_agreement.pdf')} style={{ width: '100%', height: '100%' }}></iframe>
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={handleRejectTerms}>
            Reject
          </CButton>
          <CButton color="primary" onClick={handleAcceptTerms}>
            Accept
          </CButton>
        </CModalFooter>
      </CModal>
      {/* Modal With Logout Message */}
      <CModal visible={showLoggedOutModal} className="centered-modal">
        <CModalBody>
        {t('adminDashboard.Customers.rejected_terms')}
        </CModalBody>
      </CModal>
      {/* Modal Message After Accepting Terms and Condition */}
      <CModal visible={showAccepTermsModal} className="centered-modal">
        <CModalBody>
          {t('adminDashboard.Customers.accepted_terms')}
        </CModalBody>
      </CModal>
    </div>
  );
};
export default CustomerPackages;

