import React, { useEffect, useState } from 'react';
import {
  CCard,
  CCardBody,
  CCardHeader,
  CTable,
  CTableBody,
  
  CTableRow,
  CTableDataCell,
  CFormInput
} from '@coreui/react';
import { RootState } from '../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { Cart, Pallet, ProductItem } from '../../../types';
import ProductCartModal from '../modals/ProductCartModal';
import { setPackageDiscount } from '../../../redux/slices/PackageSlice';
import { setWarehousePackageDiscount } from '../../../redux/slices/WarehousePackageSlice';
import PalletCartModal from '../modals/PalletCartModal';
import { useTranslation } from 'react-i18next';
import { isMobile, isTablet, isDesktop } from 'react-device-detect';

interface SummaryProps {
    updateSelectedProducts: (value: number, callBack?: () => void) => void;
    updateSelectedPallets?: (value: any) => void;
    updateSelectedSetArticles: (value: number) => void;
    itemsSelected?: number;
    is_warehouse_package?: boolean;
}
const OrderSummary = (props: SummaryProps) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const { updateSelectedProducts, updateSelectedSetArticles, itemsSelected, updateSelectedPallets, is_warehouse_package } = props;
    const cart: Cart = is_warehouse_package ? useSelector((state: RootState) => state.warehousePackageSlice.warehouseCart) : useSelector((state: RootState) => state.packageSlice.cart);
    const pallets: Pallet[] = is_warehouse_package ?  useSelector((state: RootState) => state.warehousePackageSlice.warehousePallets) : useSelector((state: RootState) => state.packageSlice.pallets);
    const packageTotalPrice =  is_warehouse_package ? useSelector((state: RootState) => state.warehousePackageSlice.warehousePackageTotalPrice) : useSelector((state: RootState) => state.packageSlice.packageTotalPrice);
    const discount =  is_warehouse_package ? useSelector((state: RootState) => state.warehousePackageSlice.warehouseDiscount) : useSelector((state: RootState) => state.packageSlice.discount);

    const [finalPrice, setFinalPrice] = useState<number>(0);
    const [showProductCartModal, setShowProductCartModal] = useState<boolean>(false);
    const [showPairedCartModal, setShowPairedCartModal] = useState<boolean>(false);
    const [showPalletCartModal, setShowPalletCartModal] = useState<boolean>(false);
    const [showUnpairedCartModal, setShowUnpairedCartModal] = useState<boolean>(false);
    const setArticles: ProductItem[] = is_warehouse_package ? useSelector((state: RootState) => state.warehousePackageSlice.warehouseSetArticles) : useSelector((state: RootState) => state.packageSlice.setArticles);
    const products: ProductItem[] = is_warehouse_package ? useSelector((state: RootState) => state.warehousePackageSlice.warehouseProducts) : useSelector((state: RootState) => state.packageSlice.products);  
    
    useEffect(()=> {
        setFinalPrice(packageTotalPrice - (packageTotalPrice * (discount / 100)));
    },[packageTotalPrice])
    
    const handleOpenNewTab = (product: ProductItem, url: string) => {
        // Open the link in a new tab using window.open
        const newTab: any = window.open(url, '_blank');
        const serializedProduct = JSON.stringify(product);
        newTab.localStorage.setItem('product', serializedProduct);
    };
    return (
        <CCard style={{width: '95%', marginLeft: 24}} className='margin-top-24'>
            {
                <ProductCartModal
                    headerText={"Products"}
                    isModalVisible={showProductCartModal}
                    closeModal={() => setShowProductCartModal(false)}
                    bodyText={""}
                    cartData={cart.standalone}
                    productsData={products}
                    productType="standalone"
                    onClick={(cartItem) =>{
                        handleOpenNewTab(products.filter((product) => {return product.id === cartItem})[0], '/admin/dashboard/product/details')
                    }}
                    removeFromCart={(id) => updateSelectedProducts(id)}
                />
            }
            {
                <ProductCartModal
                    headerText={"Paired Articles"}
                    isModalVisible={showPairedCartModal}
                    closeModal={() => setShowPairedCartModal(false)}
                    bodyText={""}
                    cartData={cart.paired}
                    productsData={setArticles}
                    productType="paired"
                    onClick={(cartItem) =>{
                        handleOpenNewTab(setArticles.filter((setArticle) => {return setArticle.id === cartItem})[0], '/admin/dashboard/product/details')
                    }}
                    removeFromCart={(id) => {
                        // updateSelectedSetArticles(id, setArticles.filter((product: ProductItem) => {return product.id === id})[0]?.selling_price)
                        updateSelectedSetArticles(id)
                    }}
                />
            }

            {
                <PalletCartModal
                    headerText={"Pallets"}
                    isModalVisible={showPalletCartModal}
                    closeModal={() => setShowPalletCartModal(false)}
                    bodyText={""}
                    cartData={cart.pallets}
                    productsData={pallets}
                    productType="paired"
                    onClick={(cartItem) =>{
                        // handleOpenNewTab(setArticles.filter((setArticle) => {return setArticle.id === cartItem})[0], '/admin/dashboard/product/details')
                    }}
                    removeFromCart={(id) => updateSelectedPallets && updateSelectedPallets(id)}
                />
            }
            {
                <ProductCartModal
                    headerText={"Unpaired Articles"}
                    isModalVisible={showUnpairedCartModal}
                    closeModal={() => setShowUnpairedCartModal(false)}
                    bodyText={""}
                    cartData={cart.unpaired}
                    productsData={setArticles}
                    productType="unpaired"
                    onClick={(cartItem) =>{
                        handleOpenNewTab(setArticles.filter((setArticle) => {return setArticle.id === cartItem})[0], '/admin/dashboard/product/details')
                    }}
                    removeFromCart={(id) => {
                        // updateSelectedSetArticles(id, setArticles.filter((product: ProductItem) => {return product.id === id})[0]?.selling_price)
                        updateSelectedSetArticles(id)
                    }}
                />
            }
            <CCardHeader><h4>{t('adminDashboard.Packages.package_summary')}</h4></CCardHeader>
            <CCardBody>
                <CTable>                
                    <CTableBody className='summary-item'>
                        <CTableRow>
                            <CTableDataCell onClick={()=>setShowProductCartModal(true)} className='summary-item-deep-font'>{t('adminDashboard.Products.products')}</CTableDataCell>
                            <CTableDataCell>{cart.standalone.length}</CTableDataCell>
                        </CTableRow>
                        <CTableRow>
                            <CTableDataCell onClick={()=>setShowUnpairedCartModal(true)} className='summary-item-deep-font'>Unpaired Set-Articles</CTableDataCell>
                            <CTableDataCell>{cart.unpaired.length}</CTableDataCell>
                        </CTableRow>
                        <CTableRow>
                            <CTableDataCell onClick={()=>setShowPairedCartModal(true)} className='summary-item-deep-font' >Paired Set-Articles</CTableDataCell>
                            <CTableDataCell>{cart.paired.length}</CTableDataCell>
                        </CTableRow>
                        <CTableRow>
                            <CTableDataCell onClick={()=>setShowPalletCartModal(true)} className='summary-item-deep-font' >{t('adminDashboard.Pallets.pallets')}</CTableDataCell>
                            <CTableDataCell>{cart.pallets.length}</CTableDataCell>
                        </CTableRow>
                        <CTableRow>
                            <CTableDataCell>{t('adminDashboard.CommonWords.quantity')}</CTableDataCell>
                            <CTableDataCell>{cart.paired.length + cart.unpaired.length + cart.standalone.length + cart.pallets.length}</CTableDataCell>
                        </CTableRow>
                        {
                            itemsSelected ?
                                <CTableRow>
                                    <CTableDataCell>{t('adminDashboard.Products.items_selected_percentage')}</CTableDataCell>
                                    <CTableDataCell>{itemsSelected}</CTableDataCell>
                                </CTableRow>
                             : null
                        }
                        
                        <CTableRow>
                            <CTableDataCell>{t('adminDashboard.OrdersWords.sub_total_euro')}</CTableDataCell>
                            <CTableDataCell>{Intl.NumberFormat('de-DE', { maximumFractionDigits: 2 }).format(packageTotalPrice)}</CTableDataCell>
                        </CTableRow>
                        <CTableRow>
                            <CTableDataCell>{t('adminDashboard.CommonWords.discount_percentage')}</CTableDataCell>
                            <CTableDataCell>
                                <CFormInput
                                    placeholder='Enter Discount'
                                    type='number'
                                    min={0}
                                    max={50}
                                    value={discount}
                                    onChange={(e: any) => {
                                        is_warehouse_package ? dispatch(setWarehousePackageDiscount(e.target.value)) : dispatch(setPackageDiscount(e.target.value));
                                        setFinalPrice(packageTotalPrice - (packageTotalPrice * (e.target.value / 100)))
                                    }}
                                    style={{width: isTablet ?'35%' : isMobile ? '100%' : '25%'}}
                                />
                            </CTableDataCell>
                        </CTableRow>
                        <CTableRow>
                            <CTableDataCell>{t('adminDashboard.Packages.grand_total_euro')}</CTableDataCell>
                            <CTableDataCell>{Intl.NumberFormat('de-DE', { maximumFractionDigits: 2 }).format(finalPrice)}</CTableDataCell>
                        </CTableRow>
                    </CTableBody>
                </CTable>
            </CCardBody>
        </CCard>
    );
};

export default OrderSummary;