import { useEffect, useState } from 'react'
import 'simplebar/dist/simplebar.min.css';
import { CButton, CSmartTable } from '@coreui/react-pro';
import '../../AdminStyles.css';
import { useTranslation } from 'react-i18next';
import { Utils } from '../../components/utils';
import { acceptClaim, getClaimRequests, rejectClaim } from '../../../services/ClaimAPIService';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { GenericErrorToast, GenericSuccessToast } from '../../components/create-package-components/ToastsComponent';
import { CToaster, CTooltip } from '@coreui/react';
import { ClaimAPIItem } from '../../../types';

const PendingClaims = () => {
  const claimAPIToken: string = useSelector((state: RootState) => state.authSlice.claimAPIToken);
  const [activePage, setActivePage] = useState(1)
  const [toast, addToast] = useState<any>(null);

  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [pendingClaimsData, setPendingClaimsData] = useState<ClaimAPIItem[]>([]);
  const {pendingClaimsColumns} = Utils();
  const {t} = useTranslation();
 
  const getAllClaims = () => {
    getClaimRequests(claimAPIToken).then((response) => {
      setPendingClaimsData(response.data)
    }).catch((e) => {
    });
  }
  useEffect(() => {
    getAllClaims()
  }, []);
  
  const handleOpenNewTab = (product: any) => {
    // Open the link in a new tab using window.open
    const newTab: any = window.open(
      "/admin/dashboard/product/details",
      "_blank"
    );
    const serializedProduct = JSON.stringify(product);
    newTab.localStorage.setItem("product", serializedProduct);
  };

  const accept = (product_id:  number) => {
    acceptClaim(claimAPIToken, product_id).then((response) => {
      console.log('accepted', response);
      // show popup here.
      addToast(GenericSuccessToast(()=>{addToast(null)}, response?.data?.message));
      getAllClaims();
    }).catch((e)=> {
      addToast(GenericErrorToast(()=>{addToast(null)}, 'Error! while accepting this claim.'));
      console.log('error while accepting claim');
    })
  } 
 
  const reject = (product_id:  number) => {
    rejectClaim(claimAPIToken, product_id).then((response) => {
      addToast(GenericSuccessToast(()=>{addToast(null)}, response?.data?.message));
      console.log('rejected');
      // show popup here.
      getAllClaims();
    }).catch((e)=> {
      addToast(GenericErrorToast(()=>{addToast(null)}, 'Error! while accepting this claim.'));
      console.log('error while rejecting claim');
    })
  }
  
  return (
    <div className='website-settings-main-container'>
      <CToaster push={toast} placement="top-end" />
      <h1><b>Pending Claim Requests</b></h1>
      <CSmartTable
        columns={pendingClaimsColumns}
        items={pendingClaimsData}
        itemsPerPage={itemsPerPage}
        itemsPerPageSelect
        pagination={true}
        paginationProps={{
          activePage: activePage,
          pages: Math.ceil(pendingClaimsData?.length / itemsPerPage) || 1,
        }}
        tableHeadProps={{
          color: 'info',
        }}
        tableBodyProps={{
          className: 'align-middle'
        }}
        tableProps={{
          hover: true,
          striped: true,
          responsive: true,
        }}
        onActivePageChange={(activePage) => setActivePage(activePage)}
        onColumnFilterChange={(filter) => {
          setActivePage(1)
        }}
        onItemsPerPageChange={(itemsPerPage) => {
          setActivePage(1)
          setItemsPerPage(itemsPerPage)
        }}
        scopedColumns={{
          customer_company: (item: ClaimAPIItem) => {
            return (
              <td className="text-center">
                {item.customer_company}
              </td>
            )
          },
          customer_email: (item: ClaimAPIItem) => {
            return (
              <td className="text-center">
                {item.customer_email}
              </td>
            )
          },
          customer_fname: (item: ClaimAPIItem) => {
            return (
              <td className="text-center">
                {item.customer_fname}
              </td>
            )
          },
          customer_lname: (item: ClaimAPIItem) => {
            return (
              <td className="text-center">
                {item.customer_lname}
              </td>
            )
          },
          lager_number: (item: ClaimAPIItem) => {
            return (
              <td className="text-center url-decoration"  onClick={() => handleOpenNewTab({id: item.lager_number})}>
                {item.lager_number}
              </td>
            )
          },
          order_number: (item: ClaimAPIItem) => {
            return (
              <td className="text-center">
                {item.order_number}
              </td>
            )
          },
          question_text: (item: ClaimAPIItem) => {
            return (
              <td className="text-center">
                {
                  item?.question_text?.length > 2 ?
                    <CTooltip
                      content={item?.question_text?.join(', ')}
                      placement="top"
                    >
                      <div className="text-center">
                        {`${item.question_text[0]}, ${item.question_text[1]}, .....`}
                      </div>
                    </CTooltip>
                    :
                  item?.question_text?.join(', ')
                }
               
               
              </td>
            )
          },
          salesperson_name: (item: ClaimAPIItem) => {
            return (
              <td className="text-center">
                {item.salesperson_name}
              </td>
            )
          },
          actions: (item: ClaimAPIItem) => {
            return (
              <td className="text-center">
                <CButton
                  className='elv-btn-ghost'
                  variant="ghost"
                  shape="square"
                  size="sm"
                  onClick={() => {
                    accept(item.product_id);
                  }}
                >
                  {t('adminDashboard.CommonWords.accept')}
                </CButton>
                <CButton
                  color="danger"
                  shape="square"
                  size="sm"
                  onClick={() => {
                    reject(item.product_id);
                  }}
                  style={{
                    marginLeft: 12,
                    color: 'white',
                  }}
                >
                  {t('adminDashboard.CommonWords.reject')}
                </CButton>
              </td>
            )
          },
        }}
      />
  </div>
  );
};
export default PendingClaims;
