import { CButton, CModal, CModalBody, CModalFooter, CModalHeader } from "@coreui/react";
import { useTranslation } from "react-i18next";
import { Package } from "../../../types";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

interface ConfirmPackageModalProps {
    note: string;
    showModal: boolean;
    productCart: number[];
    handleCloseModal: () => void;
    handleChangeInfo: (value: any) => void;
    handleConfirmPackage: () => void;
    showLowSelectionModal?: boolean;
    packageItem: Package | undefined;
}
const ConfirmPackageModal = (props: ConfirmPackageModalProps) => {
    const {note, showModal, productCart, handleCloseModal, handleChangeInfo, handleConfirmPackage, showLowSelectionModal, packageItem} = props;
    const {t} = useTranslation();
    const selection_percent: string = useSelector((state: RootState) => state.authSlice.selection_percent);
    return (
        <CModal visible={showModal} onClose={handleCloseModal}>
            <CModalHeader closeButton>
                <div>
                    <b>{t('adminDashboard.Packages.confirm_package')}</b>
                </div>
                {/* <CIcon icon={cilWarning} className="text-warning mr-2" /> */}
            </CModalHeader>
            <CModalBody>
                <form>
                    <div className="mb-3">
                        <div>
                            <div>{t('adminDashboard.Packages.you_have_selected')} <b className="font-bold">{productCart?.length}</b> {t('adminDashboard.Packages.items')}</div>
                            <b style={{color: 'red'}}>{packageItem !== undefined  && (productCart?.length / packageItem?.products?.length) < Number(selection_percent) ? `${t('adminDashboard.OrdersWords.customer_place_order_warning_1')} (${(Number(selection_percent) * 100).toFixed(2)}%) ${t('adminDashboard.OrdersWords.customer_place_order_warning_2')}` : null}</b>
                        </div>
                        <label htmlFor="infoTextArea" className="form-label">{t('adminDashboard.Packages.message')}:</label>
                        <textarea
                            className="form-control"
                            id="infoTextArea"
                            placeholder={`${t('adminDashboard.Packages.type_your_messsage_here')}`}
                            rows={3}
                            value={note}
                            onChange={handleChangeInfo}
                        />
                    </div>
                </form>
            </CModalBody>
            <CModalFooter>
                <CButton color="secondary" onClick={handleCloseModal}>
                {t('adminDashboard.CommonWords.close')}
                </CButton>
                <CButton color="primary" onClick={handleConfirmPackage}>
                {t('adminDashboard.CommonWords.confirm')}
                </CButton>
            </CModalFooter>
        </CModal>
    )
};
export default ConfirmPackageModal;