import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { LoggedinUser } from '../../types';

interface AuthSliceState {
    isAuthenticated: boolean;
    accessToken: string;
    user: LoggedinUser;
    claimAPIToken: string;
    selection_percent: string;
}

const initialState = { isAuthenticated: false, accessToken: "", user: {}, claimAPIToken: "", selection_percent: "" } as AuthSliceState

const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    setIsAuthenticated(state, action: PayloadAction<boolean>) {
      state.isAuthenticated = action.payload
    },
    setAccessToken(state, action: PayloadAction<string>) {
      state.accessToken = action.payload
    },
    setClaimAPIToken(state, action: PayloadAction<string>) {
      state.claimAPIToken = action.payload
    },
    setSelectionPercent(state, action: PayloadAction<string>) {
      state.selection_percent = action.payload
    },
    setLoggedinUser(state, action: PayloadAction<LoggedinUser | {}>) {
      state.user = action.payload
    },
  },
})

export const { setIsAuthenticated, setAccessToken, setLoggedinUser, setClaimAPIToken, setSelectionPercent } = authSlice.actions
export default authSlice.reducer