import { cilTruck, cilReload } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CButton, CCol, CNav, CNavItem, CRow, CSpinner } from "@coreui/react";
// import { t } from "i18next";
import ProgressBar from "@ramonak/react-progress-bar";
import { formattedPrice } from "../../constants";
import { Package } from "../../types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

interface PackageDetailNonStickyHeaderProps {
    loading: boolean;
    totalPrice: number;
    productCart: number[];
    numOfTrucks:  number;
    truckCapacity: number;
    disableConfirmButton: boolean;
    packageItem: Package | undefined;
    handleShowModal: () => void;
    calculateTruckCapacity: () => void;
}

const PackageDetailNonStickyHeader = (props: PackageDetailNonStickyHeaderProps) => {
    const {loading, numOfTrucks, handleShowModal, disableConfirmButton, truckCapacity, productCart, packageItem, totalPrice, calculateTruckCapacity} = props;
    const {t} = useTranslation();
    // const selection_percent: string = useSelector((state: RootState) => state.authSlice.selection_percent);
    return (
        <CNav style={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                <CNavItem>
                    {
                        loading ?
                            <CRow>
                                <CCol>
                                    <CSpinner />
                                </CCol>
                            </CRow>
                            : null
                    }
                    <CRow>
                        <CCol>
                            <h4>{numOfTrucks} x </h4>
                            <CIcon icon={cilTruck} className="me-2" size="3xl" />
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol>
                            {t('adminDashboard.Packages.truck_capacity_text')} ({truckCapacity} m)

                            <ProgressBar completed={`${truckCapacity}`} maxCompleted={13.5} bgColor="#23B2D9" width="100%" customLabel=" " />
                        </CCol>
                    </CRow>
                </CNavItem>
                <CNavItem>
                    <CButton
                        className='elv-btn'
                        variant="outline"
                        shape="square"
                        style={{ marginLeft: 15 }}
                        onClick={calculateTruckCapacity}
                        disabled={productCart.length == 0}
                    >
                        <CIcon
                            icon={cilReload}
                        />
                    </CButton>
                </CNavItem>
                <CNavItem>
                    <CRow>
                        <CCol>
                            {
                                packageItem && packageItem.status === 'A' ?
                                    <div style={{ marginLeft: '20px', marginRight: '12px' }}>
                                        <CButton
                                            className='elv-btn'
                                            variant="outline"
                                            shape="square"
                                            style={{ marginTop: 24 }}
                                            disabled={productCart?.length === 0 || disableConfirmButton || loading}
                                            onClick={() => { handleShowModal()}}
                                        >{t('adminDashboard.Packages.confirm_package')}</CButton>
                                    </div>
                                    : null
                            }
                        </CCol>
                    </CRow>
                </CNavItem>
                <CNavItem>
                    <CRow>
                        <CCol>
                            <h5 style={{ marginBottom: '8px', marginLeft: '12px', marginRight: '12px' }}><b>{t('adminDashboard.Packages.total_items')}: {productCart?.length}</b></h5>
                        </CCol>
                    </CRow>
                </CNavItem>
                <CNavItem>
                    <CRow>
                        <CCol>
                            <h5 style={{ marginBottom: '8px', marginLeft: '12px', marginRight: '12px' }}><b>{t('adminDashboard.Packages.grand_total_euro')}: {formattedPrice(totalPrice)}</b></h5>
                        </CCol>
                    </CRow>
                </CNavItem>
            </CNav>
    )
};
export default PackageDetailNonStickyHeader;