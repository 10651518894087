

import { useEffect, useState } from 'react';
import 'simplebar/dist/simplebar.min.css';
import { CButton,  CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle,  CSmartTable, CToaster } from '@coreui/react-pro';
import {deletePackage, filterPackageByProductID, getPackageSnapshots, getTruckCapacity, modifyPackage, revertPackageSnapshot} from '../../../services/PackageService';
import '../../AdminStyles.css';
import { Package } from '../../../types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../redux/store';
import { setPackages } from '../../../redux/slices/PackageSlice';
import { ADMIN, SALES_ASSISTANT_GROUP_ID } from '../../../constants';
import _ from 'lodash'; // Import lodash library
import SnapshotModal from '../../components/modals/SnapshotModal';
import { placeOrder } from '../../../services/OrderService';
import { downloadExcelFile } from '../../components/GenerateExcelFile';
import { CBadge, CCol, CFormFeedback, CFormInput, CFormLabel, CRow, CTooltip } from '@coreui/react';
import GenericConfirmationModal from '../../components/modals/DeleteItemModal';
import { Utils } from '../../components/utils';
import { getOrderPDFURL } from '../../components/DownloadPDF';
import { useTranslation } from 'react-i18next';
import { GenericErrorToast } from '../../components/create-package-components/ToastsComponent';

const PackagesScreen = () => {
  const navigation = useNavigate();
  const {t} = useTranslation();
  const {
    getPackagesData, getOrdersData, PackagesColumns, getPendingPackagesData, 
    returnDateFormat, getAllProductSubCategories, getAllProductArticles } = Utils();
  const authUser = useSelector((state: RootState) => state.authSlice.user);
  const packages: Package[] = useSelector((state: RootState) => state.packageSlice.packages);
  const IS_ADMIN_OR_SUPERADMIN = (authUser.is_superuser || authUser?.groups?.includes(ADMIN));
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState<any>();
  const [tempPackages, setTempPackages] = useState<Package[]>([]);
  const [state, setState] = useState<any>({
    activePageNumber: 1,
    itemsPerPage: 20,
    loading: false,
    details: [],
    userInteracted: false,
    loadMore: false,
    isModalVisible: false,
    orderConfirmationModal: false,
    deletePackageConfirmationModal: false,
    totalProducts: 0,
    errorText: '',
  });  
  const [toast, addToast] = useState<any>(null);
  useEffect(() => {
    getAllProductArticles();
    getAllProductSubCategories();
  }, []);
  const deletePackages = (id: number) => {
    setState({
      ...state,
      loading: true,
    });
    let updatedPackages = packages?.filter((packageItem: Package) => {
      return (packageItem.id !== id)
    });
    dispatch(setPackages([...updatedPackages]));

    setState({
      ...state,
      loading: false,
      deletePackageConfirmationModal: false,
    });
    deletePackage(id).then((response) => {
      getOrdersData();
      getPendingPackagesData();
    }).catch((e) => {
      setState({
        ...state,
        loading: false,
        deletePackageConfirmationModal: false,
      });
      getPackagesData();
      getPendingPackagesData();
      getOrdersData();
      console.log('delete package error: ', e);
    })
  };
  
  const getAllSnapshots = (selectedPackageItem: Package) => {
    setState({
      ...state,
      loading: true,
    })
    selectedPackageItem.id && getPackageSnapshots(selectedPackageItem.id).then((response) => {
      setState({
        ...state,
        snapshots: response.data,
        isModalVisible: true,
        selectedPackage: selectedPackageItem,
      })
    }).catch((e) => {
      setState({
        ...state,
        isModalVisible: false,
      })
    });
}

const revert = (snapshotId: number) => {
  setState({
    ...state,
    loading: true,
    isModalVisible: false,
  })

  if(state.selectedPackage?.id) {
    revertPackageSnapshot(state.selectedPackage.id, snapshotId).then((response) => {        
      getPackagesData();
      getPendingPackagesData();
      setState({
        ...state,
        loading: false,
        isModalVisible: false,
      })
    }).catch((e) => {
        getPackagesData();
        getPendingPackagesData();
        setState({
          ...state,
          loading: false,
          isModalVisible: false,
        })
        // console.log('Error while reverting snapshot: ', e);
    });
  }
}

const confirm = (packageItem: Package) => {   
  let products = packageItem?.products?.map(number => `'${number}'`).join(',');

  let form_data = {
    "lager_numbers": '(' +  products + ')',
    fetched_pallet_count: packageItem?.pallets_products?.length ? packageItem?.pallets_products?.length : 0,
  }
  getTruckCapacity(form_data).then((response) => {
    const totalCapacityOccupied = Number(response?.data?.truck_details?.occupancy["total_occupied_in_meters"]);
    if(!totalCapacityOccupied ) {
      confirmOrder(packageItem, 0);
    }
    else {
      confirmOrder(packageItem, totalCapacityOccupied);
    }
  }).catch((e)=> {
      console.log('Truck capacity Exception: ', e);
      confirmOrder(packageItem, 0);
  });
}

const confirmOrder = (packageItem: Package, totalCapacityOccupied: number) => {
  let formData =  {
    "products": packageItem.products,
    accept_order: true,
  }
  setState({
    ...state,
    loading: true,
    orderConfirmationModal: true,
  })
  modifyPackage(packageItem.id, formData).then((response) => {
    const itemSelected = (packageItem.quantity / state.totalProducts) * 100;
    let form_data =  {
      package: packageItem.id,
      truck_capacity: totalCapacityOccupied,
      items_selected: itemSelected.toFixed(2),
    };
    placeOrder(form_data).then((response) => {
      //console.log(response.data);
      getOrderPDFURL(response.data.id);
      getPackagesData();
      getPendingPackagesData();
      navigation('/admin/dashboard/orders/requests', {state: {reload: true}});
    }).catch((e: any) =>{
      addToast(GenericErrorToast(()=>{addToast(null)}, e?.response?.data));
      setState({
        ...state,
        loading: false,
        orderConfirmationModal: false,
      })
    })
  }).catch((e: any) =>{
    addToast(GenericErrorToast(()=>{addToast(null)}, e?.response?.data));
  })
}

const calculateItemsSelected = (packageItem: Package) => {
  getPackageSnapshots(packageItem.id).then((response) => {
    setState({
      ...state,
      selectedPackageItem: packageItem,
      orderConfirmationModal: true,
      totalProducts:  response?.data[0]?.products.length ? response?.data[0]?.products.length : packageItem.quantity ,
    })
  }).catch((e) => {
    setState({
      ...state,
      selectedPackageItem: packageItem,
      orderConfirmationModal: true,
      totalProducts: packageItem.quantity,
    })
  });
}
const filterPackages = (product_id: number) => {
  filterPackageByProductID(product_id).then((response) => {
    let updatedPackagesArray: Package[]= [];

    updatedPackagesArray.push({
      ...response?.data,
      created_at: new Date(response?.data?.created_at),
    });

    setTempPackages(updatedPackagesArray);
    setState({
      ...state,
      errorText: '',
    });
  }).catch((e) => {
    if(e?.response?.data?.error) {
      setState({
        ...state,
        errorText: e?.response?.data?.error
      });
    }
    setTempPackages([]);
  });
}

  return (
    <div className='website-settings-main-container'>
      <CToaster push={toast} placement="top-end" />
      <GenericConfirmationModal
        loading={state.loading}
        isModalVisible={state.orderConfirmationModal}
        closeModal={()=> setState({...state, orderConfirmationModal: false})}
        headerText={`${t('adminDashboard.Packages.are_you_sure')}`}
        bodyText={`${t('adminDashboard.PendingPackages.place_order_confirmation')}`}
        confirmButtonText={`${t('adminDashboard.Packages.place_order')}`}
        onClickConfirmation={() => {
          confirm(state.selectedPackageItem)
        }}
      />
      <GenericConfirmationModal
        isModalVisible={state.deletePackageConfirmationModal}
        closeModal={()=> setState({...state, deletePackageConfirmationModal: false})}
        headerText={`${t('adminDashboard.Packages.are_you_sure')}`}
        bodyText={`${t('adminDashboard.SupplyInventory.confirm_delete_item')}`}
        confirmButtonText={`${t('adminDashboard.CommonWords.delete')}`}
        onClickConfirmation={() => {
          deletePackages(state.selectedPackageItem.id);
        }}
      />
      <SnapshotModal 
        isModalVisible={state.isModalVisible}
        headerText={`${t('adminDashboard.Packages.revert_to_prev_snapshots')}`}
        bodyText={`${t('adminDashboard.Packages.which_snapshot')}`}
        closeModal={() => {
          setState({
            ...state,
            isModalVisible: false,
          })        
        }}
        snapshots={state.snapshots}
        revert={revert}
      />
      <h1><b>{t('adminDashboard.Packages.sidebar_text')}</b></h1>      
        <CButton className='margin-bottom-24' onClick={() => navigation('/admin/dashboard/create/package')}>{t('adminDashboard.Packages.create_package_text')}</CButton>
        <CRow className='margin-bottom-44'>
          <CCol xs={3}>
            <CFormInput
              placeholder={`${t('adminDashboard.Products.product_id_search')}`}
              value={searchText}
              onChange={(e: any) => {
                setSearchText(e.target.value);
                if(e.target.value.length >= 8) {
                 filterPackages(e.target.value);
                }
                else {
                  setTempPackages([])
                }
              }}
              style={{marginTop: 6, marginBottom: 16}}
            />
            {
            state.errorText?.length > 0 ?
              <CFormFeedback style={{color: 'red'}}>Error! {state.errorText}</CFormFeedback>
            : null
          }
          </CCol>
        </CRow>
       
        <CSmartTable
          columns={PackagesColumns}
          columnSorter={true}
          tableFilter
          tableFilterLabel={`${t('adminDashboard.CommonWords.search')}`}
          tableFilterPlaceholder={`${t('adminDashboard.CommonWords.search')}`}
          items={tempPackages?.length > 0 ? tempPackages : packages}
          itemsPerPage={state.itemsPerPage}
          itemsPerPageSelect
          itemsPerPageLabel={`${t('adminDashboard.CommonWords.items_per_page')}`}
          noItemsLabel={`${t('adminDashboard.CommonWords.no_items_found')}`}
          loading={state.loading}
          pagination={true}
          tableHeadProps={{
            color: 'info',
          }}
          tableProps={{
            hover: true,
            responsive: true,
            striped: true,
          }}

          tableBodyProps={{
            className: 'align-middle'
          }}

          scopedColumns={{
            id: (item: Package) => {
              return (
                <td className="text-center">
                  {item.id}
                </td>
              )
            },
            creator_first_name: (item: Package) => {
              return (
                <td className="text-center">
                  {item.creator_first_name}
                </td>
              )
            },
            customer_company: (item: Package) => {
              return (
                <td className="text-center">
                  {item.customer_company}
                </td>
              )
            },
            products_selection_percentage: (item: Package) => {
              return (
                <td className="text-center">
                  {
                    item.products_selection_percentage === "0.000" ?
                    "-"
                    :
                    (Number(item.products_selection_percentage) * 100).toFixed(2)
                  }
                </td>
              )
            },
            created_at: (item: Package) => {
              return (
                <td className="text-center">
                  {returnDateFormat(item.created_at)}
                </td>
              )
            },
            status: (item: any) => {
              return (
                <td className='text-center'>
                  <CBadge color={item.status == 'A' ? 'warning' : item.status == 'M' ? 'info' : item.status == 'F' ? 'success' : 'light'}>
                    <b>
                      {item.status && item.status == 'A' ? t('adminDashboard.CommonWords.pending') 
                        : item.status && item.status == 'M' ? t('adminDashboard.CommonWords.order_requested') 
                        : item.status && item.status == 'F' ? t('adminDashboard.OrdersWords.ordered') 
                        : null
                      }
                    </b>
                  </CBadge>
                  <div>
                    {
                      item.is_warehouse_package ? <CBadge className='margin-top-12' color={'dark'}><b>{t('adminDashboard.Warehouse.sidebar_text') }</b></CBadge> : null
                    }
                  </div>
                </td>
              )
            },
            sub_total: (item: any) => {
              return (
                <td className='text-center'>
                   {Intl.NumberFormat('de-DE', { maximumFractionDigits: 2 }).format(item.sub_total)}
                </td>
              )
            },
            discount: (item: any) => {
              return (
                <td className='text-center'>
                  {item.discount}
                </td>
              )
            },
            total: (item: Package) => {
              return (
                <td className='text-center'>
                  {Intl.NumberFormat('de-DE', { maximumFractionDigits: 2 }).format(item.total)}
                </td>
              )
            },
            quantity: (item: Package) => {
              return (
                <td className='text-center'>
                  {item.quantity}
                </td>
              )
            },
            creator_name: (item: any) => {
              return (
                <td className='text-center'>
                  {item.creator_name}
                </td>
              )
            },
            supplies: (item: any) => {
              const visibleSupplies = item.supplies.slice(0, 3);
              const tooltipSupplies = item.supplies.slice(3);
              return (
                <td>
                  <CTooltip
                    content={tooltipSupplies.length > 0 ? tooltipSupplies.join(', ') : 'No more supplies'}
                    placement="top"
                  >
                    <div className="text-center" style={{ maxWidth: 220, wordWrap: 'break-word' }}>
                      {visibleSupplies.join(', ')}
                    </div>
                  </CTooltip>
                </td>
              );
            },
            note: (item: any) => {
              return (
                <td className='text-center'>
                  {
                    item?.note?.length > 10  ?
                    <CTooltip
                      content={item.note}
                      placement="top"
                    >
                      <div className="text-center" style={{ maxWidth: 100, wordWrap: 'break-word' }}>
                         {`${item?.note?.slice(0, 10)}...`}
                      </div>
                    
                    </CTooltip>
                    :  item?.note || "--"
                  }
                </td>
              )
            },
            show_details: (item: any) => {
              return (
                <td className="text-center">
                  <CDropdown>
                    <CDropdownToggle className="elv-btn" href="#" style={{width: 110}}>{t('adminDashboard.CommonWords.action')}</CDropdownToggle>
                    <CDropdownMenu style={{position: 'absolute', right: 20}}>
                        <CDropdownItem  onClick={() =>  {downloadExcelFile(item.id, true)}}>{t('adminDashboard.CommonWords.download_excel')}</CDropdownItem>
                        <CDropdownItem  onClick={() =>  { navigation('/admin/dashboard/packages/details', {state: {packageItem: item}})}}>{t('adminDashboard.CommonWords.view')}</CDropdownItem>
                        {item?.customer_company && (((IS_ADMIN_OR_SUPERADMIN || authUser?.groups?.includes(SALES_ASSISTANT_GROUP_ID)) && item.status != 'F') || ((item.status != 'F') && (item.creator_id == authUser.id))) ? <CDropdownItem  onClick={() =>  {calculateItemsSelected(item)}}>{t('adminDashboard.CommonWords.place_order')}</CDropdownItem> :  null}
                        {(IS_ADMIN_OR_SUPERADMIN || authUser?.groups?.includes(SALES_ASSISTANT_GROUP_ID)) || (item.creator_id == authUser.id) ?  <CDropdownItem  onClick={() => {
                          if (item.is_warehouse_package) {
                            navigation('/admin/dashboard/create/warehouse-package', {state: {packageItem: item.id}})
                          }
                          else {
                            navigation('/admin/dashboard/edit/package', {state: {packageId: item.id}})
                          }
                        }}>{t('adminDashboard.CommonWords.edit')}</CDropdownItem> : null}
                       
                        {!item.is_warehouse_package && (IS_ADMIN_OR_SUPERADMIN || authUser?.groups?.includes(SALES_ASSISTANT_GROUP_ID) || ((item.status != 'F') && (item.creator_id == authUser.id))) ? <CDropdownItem  onClick={() =>  {getAllSnapshots(item)}}>{t('adminDashboard.CommonWords.revert')}</CDropdownItem> : null}
                        {IS_ADMIN_OR_SUPERADMIN || authUser?.groups?.includes(SALES_ASSISTANT_GROUP_ID) || (item.creator_id == authUser.id) ? <CDropdownItem  onClick={() =>  {
                          setState({
                            ...state,
                            selectedPackageItem: item,
                            deletePackageConfirmationModal: true,
                          })
                          }}>{t('adminDashboard.CommonWords.delete')}</CDropdownItem> : null}
                    </CDropdownMenu>
                  </CDropdown>
                </td>
              )
            }
          }}
        />
    </div>
  );
};
export default PackagesScreen;
