import { useEffect, useState } from 'react'
import 'simplebar/dist/simplebar.min.css';
import '../../AdminStyles.css';
import { CButton, CFormInput, CForm, CHeader, CFormLabel, CRow, CToaster, CSpinner, CCol, CFormCheck} from '@coreui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { createStaffUser, updateStaffUser } from '../../../services/StaffService';
import {UserCreatedErrorToast, UserCreatedSuccessToast, UserEditedSuccessToast, UserEditedErrorToast, GenericErrorToast} from '../../components/create-package-components/ToastsComponent';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { ADMIN, BACK_OFFICE_GROUP_ID, SUPER_ADMIN, SUPPLIER_GROUP_ID } from '../../../constants';
import { DropDownItem, LoggedinUser, PermissionGroup, Supplier } from '../../../types';
import Select, {components} from 'react-select';
import { addSuppliers } from '../../../services/ProductService';

const AddStaffUserScreen = () => {
    const state = useLocation();
    const navigation = useNavigate();
    const permissionGroups = useSelector((state: RootState) => state.usersSlice.permissionGroups);
    const authUser = useSelector((state: RootState) => state.authSlice.user);
    const suppliers = useSelector((state: RootState) => state.productSlice.suppliers);
    const permissionGroupsOptions: DropDownItem[] = (permissionGroups.filter((item) => (item.id !== 1 && item.id !== 3)).map((group: PermissionGroup) => ({ value: group.id, label: group.name }))).sort((a, b) => a.label.localeCompare(b.label));
    const supplierOptions: DropDownItem[] = (suppliers.map((supplier: Supplier) => ({ value: supplier.id, label: supplier.name }))).sort((a, b) => a.label.localeCompare(b.label));
  
    const [toast, addToast] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [email, setEmail] = useState<string>(state?.state?.userObject?.user?.email.length > 0 ? state?.state?.userObject?.user?.email :  '');
    const [password, setPassword] = useState<string>('');
    const [firstName, setFirstName] = useState<string>(state?.state?.userObject?.user?.first_name.length > 0 ? state?.state?.userObject?.user?.first_name : '');
    const [lastName, setLastName] = useState<string>(state?.state?.userObject?.user?.last_name.length > 0 ? state?.state?.userObject?.user?.last_name : '');
    const [selectedRolesIds, setSelectedRolesIds] = useState<DropDownItem[]>([]);
    const [selectedSupplier, setSelectedSupplier] = useState<DropDownItem>();
    const [isActive, setIsActive] = useState<boolean>(state?.state?.userObject?.is_active);

    const [errorMessage, setErrorMessage] = useState<string>("");

    const IS_ADMIN_OR_SUPERADMIN = (authUser.is_superuser || authUser?.groups?.includes(ADMIN)  || authUser?.groups?.includes(BACK_OFFICE_GROUP_ID)  || authUser?.groups?.includes(SUPER_ADMIN));

    useEffect(()=> {        
        if(state?.state?.editText) {
           
            let updatedSelectedRolesIds: DropDownItem [] = [];            
            permissionGroups.map((group: any) => {
                if(state?.state?.userObject?.role.includes(group.name)){
                    updatedSelectedRolesIds.push({value: group.id, label: group.name});
                }
            })
            setSelectedRolesIds(updatedSelectedRolesIds)
        }
     }, []);   
 
    const addStaffData = () => {
        if(state?.state?.editText) {
            // update staff member.
            setIsLoading(true);
            
            let staffUserObject = {
                    first_name: firstName,
                    last_name: lastName,
                    email,
                    groups: [selectedRolesIds[0]?.value],
                    is_active: isActive,
               }              
               updateStaffUser(state.state.userObject?.user?.id, staffUserObject).then((response) => {
                    setIsLoading(false);
                    addToast(UserEditedSuccessToast(()=>{
                        addToast(null);
                        navigation('/admin/dashboard/users');
                    }));
               }).catch((e) => {
                    setIsLoading(false);
                    addToast(UserEditedErrorToast(()=>{
                        addToast(null);
                        navigation('/admin/dashboard/users');
                    }));                   
               });
        }
        else {
            setIsLoading(true);
          
            let staffUserObject = {
                first_name: firstName,
                last_name: lastName,
                email,
                password1: password,
                password2: password,
                groups: [selectedRolesIds[0]?.value],
                supplier: selectedSupplier?.value
            }
            createStaffUser(staffUserObject).then((response) => {
                setIsLoading(false);
                addToast(UserCreatedSuccessToast(()=>{
                    addToast(null);
                    navigation('/admin/dashboard/users');
                }));
            }).catch((e) => {
                setIsLoading(false);
                if(e?.response?.data?.email) {
                    setErrorMessage(e?.response?.data?.email);
                    addToast(GenericErrorToast(()=>{
                        addToast(null);
                    }, `${e?.response?.data?.email}`));
                }
                else if(e?.response?.data?.password1) {
                    setErrorMessage(e?.response?.data?.password1);
                    addToast(GenericErrorToast(()=>{
                        addToast(null);
                    }, `${e?.response?.data?.password1}`));
                }
                else if(e?.response?.data?.non_field_errors) {
                    setErrorMessage(e?.response?.data?.non_field_errors);
                    addToast(GenericErrorToast(()=>{
                        addToast(null);
                    }, `${e?.response?.data?.non_field_errors}`));
                }
            });
        
        }
    }
    return(
        <div className='website-settings-main-container'>
            <CToaster push={toast} placement="top-end" />
            <CHeader><h2><b>{state?.state?.editText? state.state.editText :  "Add New Staff User"}</b></h2></CHeader>
            <CForm style={{marginBottom: 16, marginTop: 24}}>
            <CRow>
                <CCol xs={7} className='padding-top-16'>
                    <CFormLabel><b>Email</b></CFormLabel>
                    <CFormInput
                        type={"email"}
                        placeholder='Enter Email'
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value);
                        }}                      
                        disabled={state?.state?.editText ? true : false}
                    />
                </CCol>
            </CRow>
            <CRow>
                <CCol xs={7} className='padding-top-16'>
                    {
                        !state?.state?.editText? <CFormLabel><b>Password</b></CFormLabel> : null
                    }
                    {
                        !state?.state?.editText? 
                            <CFormInput
                                type={"password"}
                                placeholder='Enter User Password'
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                }}
                               
                            />
                        : null
                    }
                </CCol>
            </CRow>

            <CRow>
                <CCol xs={7} className='padding-top-16'>
                    <CFormLabel><b>First Name</b></CFormLabel>
                    <CFormInput
                        placeholder='Enter First Name'
                        value={firstName}
                        onChange={(e) => {
                            setFirstName(e.target.value);
                        }}
                        
                    />
                </CCol>
            </CRow>

            <CRow>
                <CCol xs={7} className='padding-top-16'>
                    <CFormLabel><b>Last Name</b></CFormLabel>
                    <CFormInput
                        placeholder='Enter Last Name'
                        value={lastName}
                        onChange={(e) => {
                            setLastName(e.target.value);
                        }}
                       
                    />
                </CCol>
            </CRow>
                {
                    IS_ADMIN_OR_SUPERADMIN ?
                    <>
                        <CRow>
                            <CCol xs={7} className='padding-top-16'>
                                <CFormLabel><b>Role</b></CFormLabel>
                                <Select
                                    options={permissionGroupsOptions}
                                    onChange={(option: any) => {
                                        setSelectedRolesIds([option]);
                                    }}
                                />
                            </CCol>
                        </CRow>
                    </>
                    : null
                }
                {
                    selectedRolesIds[0]?.label?.includes('Supplier') ?
                        <CRow>
                            <CCol xs={7} className='padding-top-16'>
                                <CFormLabel><b>Select supplier</b></CFormLabel>
                                <Select
                                    options={supplierOptions}
                                    onChange={(option: any) => {
                                        setSelectedSupplier(option);
                                    }}
                                />
                            </CCol>
                        </CRow>
                    : null
                }
                {
                    // Update the status of user active -- inactive
                    state?.state?.editText? 
                    <CRow>
                        <CCol xs={12} md={6} lg={4} className='padding-top-16'>
                            <CFormCheck 
                                type="checkbox"
                                checked={isActive}
                                label={'Is Active'}
                                onClick={() => setIsActive(!isActive)}
                            />
                        </CCol>
                    </CRow>
                    : null
                }
                {
                    errorMessage && errorMessage.length > 0 ?
                        <p style={{color: 'red', marginTop: 8}}>Error! {errorMessage}</p>
                    :    null
                }
                <CRow>
                    <CCol xs={7} className='padding-top-16'>
                        <CButton
                            style={{ marginTop: 24}} 
                            onClick={addStaffData}
                            disabled={
                                email?.length === 0 || (!state?.state?.editText && password?.length === 0) || 
                                firstName?.length === 0 || lastName?.length === 0 || selectedRolesIds.length === 0
                            }    
                        >
                            {isLoading? <CSpinner /> : state?.state?.buttonText? state.state.buttonText : "Add"}
                        </CButton>
                    </CCol>
                </CRow>
            </CForm>
        </div>
    )
};
export default AddStaffUserScreen;
