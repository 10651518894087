import axios from "axios";
import {BACKEND_API_URL, CLAIM_API_URL} from "../constants";
import { AuthUser } from "../types";

const api = axios.create({
    baseURL: CLAIM_API_URL,
    headers: {
        // 'Content-Type': 'application/json',
        // 'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MzI4MDQ4NDMsImlhdCI6MTczMjcxODQ0Mywic3ViIjoxfQ.-8pRh86joFL2HhT8vE-jYgtCtuxnLZbCuy5TXePUBjg'
    }
  });

export const loginClaimAPI = (email: string, password: any) => {
    return api.post(
        `auth/login_for_portal`,
       {
        email: email,
        password: password,
       },
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
}
export const getClaimRequests = (token: string) => {
    const claimApi = axios.create({
        baseURL: CLAIM_API_URL,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
      });
    return claimApi.post(
        `claim/sales_person_claim_notification`,
        {
            "language_code": "ENG"
        }
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
}

export const acceptClaim = (token: string, product_id: number) => {
    const claimApi = axios.create({
        baseURL: CLAIM_API_URL,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
      });
    return claimApi.post(
        `claim/accept_reject_claim`,
        {
            "product_id":product_id,
            "is_reject": 0,
            "is_accepted":1
        }
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
}

export const rejectClaim = (token: string, product_id: number) => {
    const claimApi = axios.create({
        baseURL: CLAIM_API_URL,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
      });
    return claimApi.post(
        `claim/accept_reject_claim`,
        {
            "product_id": product_id,
            "is_reject":1,
            "is_accepted":0
        }
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
}
