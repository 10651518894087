import React, { ReactNode } from 'react';
import CIcon from '@coreui/icons-react';
import {
  cilBasket,
  cilInbox,
  cilSpeedometer,
  cilCash,
  cilDollar,
  cilUser,
  cilUserPlus,
  cilHome,
  cilTruck,
  cilFactory,
  cilSettings,
  cilPeople,
  cibGoogleAnalytics,
  cilBarChart,
  cilChart,
  cilChartPie,
} from '@coreui/icons';
import { CNavGroup, CNavItem, CNavTitle } from '@coreui/react';
const salesPersonDashboardItems = [
  {
    component: CNavTitle,
    name: 'Navigation',
  },
  {
    component: CNavItem,
    name: 'MainDashboard',
    to: '/admin/dashboard/home',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  },
  // {
  //   component: CNavItem,
  //   name: 'Customers',
  //   to: '/admin/dashboard/customers',
  //   icon: <CIcon icon={cilPeople} customClassName="nav-icon" />,
  // },
  {
    component: CNavItem,
    name: 'Products',
    to: '/admin/dashboard/products',
    icon: <CIcon icon={cilFactory} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Packages',
    to: '/admin/dashboard/packages',
    icon: <CIcon icon={cilInbox} customClassName="nav-icon" />,  
  },
  
  // {
  //   component: CNavItem,
  //   name: 'PendingPackages',
  //   to: '/admin/dashboard/pending/packages',
  //   icon: <CIcon icon={cilInbox} customClassName="nav-icon" />,  
  // },
  {
    component: CNavItem,
    name: 'Warehouse',
    to: '/admin/dashboard/warehouse-carts',
    icon: <CIcon icon={cilHome} customClassName="nav-icon" />,
  },
  
  {
    component: CNavItem,
    name: 'OrderRequests',
    to: '/admin/dashboard/orders/requests',
    icon: <CIcon icon={cilTruck} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'WebsiteSettings',
    to: '/admin/dashboard/settings',
    icon: <CIcon icon={cilSettings} customClassName="nav-icon" />,
  },
];
const salesAssistantDashboardItems = [
  {
    component: CNavTitle,
    name: 'Navigation',
  },
  {
    component: CNavItem,
    name: 'MainDashboard',
    to: '/admin/dashboard/home',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Customers',
    to: '/admin/dashboard/customers',
    icon: <CIcon icon={cilPeople} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Packages',
    to: '/admin/dashboard/packages',
    icon: <CIcon icon={cilInbox} customClassName="nav-icon" />,  
  },
  
  // {
  //   component: CNavItem,
  //   name: 'PendingPackages',
  //   to: '/admin/dashboard/pending/packages',
  //   icon: <CIcon icon={cilInbox} customClassName="nav-icon" />,  
  // },
  {
    component: CNavItem,
    name: 'Warehouse',
    to: '/admin/dashboard/warehouse-carts',
    icon: <CIcon icon={cilHome} customClassName="nav-icon" />,
  },
  
  {
    component: CNavItem,
    name: 'OrderRequests',
    to: '/admin/dashboard/orders/requests',
    icon: <CIcon icon={cilTruck} customClassName="nav-icon" />,
  },
  
  {
    component: CNavItem,
    name: 'Products',
    to: '/admin/dashboard/products',
    icon: <CIcon icon={cilFactory} customClassName="nav-icon" />,
  },

  {
    component: CNavItem,
    name: 'SupplyManagement',
    to: '/admin/dashboard/supplies',
    icon: <CIcon icon={cilBasket} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'SupplyInventory',
    to: '/admin/dashboard/supply-inventory',
    icon: <CIcon icon={cilBasket} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'CatalogManagement',
    to: '/admin/dashboard/catalog',
    icon: <CIcon icon={cilBasket} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'PendingCustomerRequests',
    to: '/admin/dashboard/pending-customers-requests',
    icon: <CIcon icon={cilPeople} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'WebsiteSettings',
    to: '/admin/dashboard/settings',
    icon: <CIcon icon={cilSettings} customClassName="nav-icon" />,
  },
];

const supplierDashboardItems = [
  {
    component: CNavTitle,
    name: 'Navigation',
  },
 
  {
    component: CNavItem,
    name: 'MainDashboard',
    to: '/admin/dashboard/home',

    icon: <CIcon icon={cilBasket} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'WebsiteSettings',
    to: '/admin/dashboard/settings',
    icon: <CIcon icon={cilSettings} customClassName="nav-icon" />,
  },
  
];
const warehouseManagerDashboardItems = [
  {
    component: CNavTitle,
    name: 'Navigation',
  },
 
  {
    component: CNavItem,
    name: 'Products',
    to: '/admin/dashboard/products',
    icon: <CIcon icon={cilBasket} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'WebsiteSettings',
    to: '/admin/dashboard/settings',
    icon: <CIcon icon={cilSettings} customClassName="nav-icon" />,
  },
  
];

const backOfficeDashboardItems = [
  {
    component: CNavTitle,
    name: 'Dashboard',
  },
  {
    component: CNavItem,
    name: 'MainDashboard',
    to: '/admin/dashboard/home',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Team',
    to: '/admin/dashboard/users',
    icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Customers',
    to: '/admin/dashboard/customers',
    icon: <CIcon icon={cilPeople} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Products',
    to: '/admin/dashboard/products',
    icon: <CIcon icon={cilFactory} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'SupplyManagement',
    to: '/admin/dashboard/supplies',
    icon: <CIcon icon={cilBasket} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'SupplyInventory',
    to: '/admin/dashboard/supply-inventory',
    icon: <CIcon icon={cilBasket} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'CatalogManagement',
    to: '/admin/dashboard/catalog',
    icon: <CIcon icon={cilBasket} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'PendingCustomerRequests',
    to: '/admin/dashboard/pending-customers-requests',
    icon: <CIcon icon={cilPeople} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'WebsiteSettings',
    to: '/admin/dashboard/settings',
    icon: <CIcon icon={cilSettings} customClassName="nav-icon" />,
  },
];
const salesAnalyticsDashboardItems = [ // This sales portal only accessible by admins.
  {
    component: CNavTitle,
    name: 'Analytics',
  },
  {
    component: CNavGroup,
    name: 'Analytics',    
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'ProfitAndTurnover',
        to: '/admin/dashboard/analytics',
        icon: <CIcon icon={cilCash} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: 'ScanningTime',
        to: '/admin/dashboard/scanning-time',
        icon: <CIcon icon={cilCash} customClassName="nav-icon" />,
    
      },
      {
        component: CNavItem,
        name: 'SalesPersonComparison',
        to: '/admin/dashboard/sales-person-comparison',
        icon: <CIcon icon={cilDollar} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: 'SalesPersonCategoriesAnalysis',
        to: '/admin/dashboard/sales-person-cat-analysis',
        icon: <CIcon icon={cibGoogleAnalytics} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: 'SalesPersonBrandAnalysis',
        to: '/admin/dashboard/sales-person-brand-analysis',
        icon: <CIcon icon={cibGoogleAnalytics} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: 'SalesPersonGradeAnalysis',
        to: '/admin/dashboard/sales-person-grade-analysis',
        icon: <CIcon icon={cibGoogleAnalytics} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: 'SalesCompaniesComparison',
        to: '/admin/dashboard/sales-companies-comparison',
        icon: <CIcon icon={cilBarChart} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: 'SalesCompaniesCategories',
        to: '/admin/dashboard/sales-companies-categories',
        icon: <CIcon icon={cilChartPie} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: 'SalesCompaniesBrands',
        to: '/admin/dashboard/sales-companies-brands',
        icon: <CIcon icon={cilChartPie} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: 'SalesCompaniesGrades',
        to: '/admin/dashboard/sales-companies-grades',
        icon: <CIcon icon={cilChartPie} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: 'SupplyAnalysis',
        to: '/admin/dashboard/supply-analysis',
        icon: <CIcon icon={cilBarChart} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: 'StockAnalysis',
        to: '/admin/dashboard/stock-analysis',
        icon: <CIcon icon={cilBarChart} customClassName="nav-icon" />,
      }
    ],
  },
]
const adminDashboardItems = [
  {
    component: CNavTitle,
    name: 'Navigation',
  },
 
  {
    component: CNavItem,
    name: 'MainDashboard',
    to: '/admin/dashboard/home',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  }, 
  {
    component: CNavItem,
    name: 'Team',
    to: '/admin/dashboard/users',
    icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Customers',
    to: '/admin/dashboard/customers',
    icon: <CIcon icon={cilPeople} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'PendingCustomerRequests',
    to: '/admin/dashboard/pending-customers-requests',
    icon: <CIcon icon={cilPeople} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Packages',
    to: '/admin/dashboard/packages',
    icon: <CIcon icon={cilInbox} customClassName="nav-icon" />,
  
  },
  // {
  //   component: CNavItem,
  //   name: 'PendingPackages',
  //   to: '/admin/dashboard/pending/packages',
  //   icon: <CIcon icon={cilInbox} customClassName="nav-icon" />,
  
  // },
  {
    component: CNavItem,
    name: 'Warehouse',
    to: '/admin/dashboard/warehouse-carts',
    icon: <CIcon icon={cilHome} customClassName="nav-icon" />,
  },
  
  {
    component: CNavItem,
    name: 'OrderRequests',
    to: '/admin/dashboard/orders/requests',
    icon: <CIcon icon={cilTruck} customClassName="nav-icon" />,
  },

  {
    component: CNavItem,
    name: 'Products',
    to: '/admin/dashboard/products',
    icon: <CIcon icon={cilFactory} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'SupplyManagement',
    to: '/admin/dashboard/supplies',
    icon: <CIcon icon={cilBasket} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'SupplyInventory',
    to: '/admin/dashboard/supply-inventory',
    icon: <CIcon icon={cilBasket} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'CatalogManagement',
    to: '/admin/dashboard/catalog',
    icon: <CIcon icon={cilBasket} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'RightsManagement',
    to: '/admin/dashboard/permission-groups',
    icon: <CIcon icon={cilUserPlus} customClassName="nav-icon" />,
  }, 
  {
    component: CNavItem,
    name: 'WebsiteSettings',
    to: '/admin/dashboard/settings',
    icon: <CIcon icon={cilSettings} customClassName="nav-icon" />,
  },
]

export default {
  // salesAnalyticsDashboardItems: salesAnalyticsDashboardItems,
  salesPersonDashboardItems: salesPersonDashboardItems,
  salesAssistantDashboardItems: salesAssistantDashboardItems,
  backOfficeDashboardItems: backOfficeDashboardItems,
  claimsSupportDashboardItems: backOfficeDashboardItems,
  adminDashboardItems: adminDashboardItems,
  supplierDashboardItems: supplierDashboardItems,
  warehouseManagerDashboardItems: warehouseManagerDashboardItems,
};
